/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, Button, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kavárna MANETKA - Akce"} description={"Akce pořádané kavárnou a cukrárnou"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1rfnuyl pb--60" style={{"paddingTop":203}} name={"akce"} layout={"l1"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/34102/97f82f6647d84d6e97facae83da6db4c_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/34102/97f82f6647d84d6e97facae83da6db4c_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/34102/97f82f6647d84d6e97facae83da6db4c_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/34102/97f82f6647d84d6e97facae83da6db4c_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/34102/97f82f6647d84d6e97facae83da6db4c_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/34102/97f82f6647d84d6e97facae83da6db4c_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/34102/97f82f6647d84d6e97facae83da6db4c_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/34102/97f82f6647d84d6e97facae83da6db4c_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1164,"paddingTop":44}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--shadow4 ff--2 fs--86 w--400" style={{"maxWidth":1022,"paddingRight":0}} content={"<span style=\"color: var(--white); font-weight: bold;\">Letní koktejl párty</span><br>"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--shadow4 ff--2 fs--48 w--400" content={"<span style=\"color: var(--white); font-weight: bold;\">pátek 23. srpna 2024<br>od 16 hod</span><br>"}>
              </Subtitle>

              <Text className="text-box" content={"&nbsp; &nbsp;"}>
              </Text>

              <Button className="btn-box btn-box--hvr4 btn-box--pbtn5 btn-box--shape5 btn-box--shadow5 btn-box--center fs--22" style={{"backgroundColor":"var(--white)"}} content={"<span style=\"color: rgb(88, 92, 99);\">více informací</span><br>"} use={"page"} href={"/akce#letni_koktejl_party"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":134,"paddingBottom":132,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"letni_koktejl_party"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" style={{"maxWidth":2074,"paddingRight":39}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--43 lh--14" content={"<span style=\"color: var(--black);\">Náš tip na pátek 23. srpna 2024</span>"}>
              </Title>

              <Title className="title-box" style={{"maxWidth":694,"marginTop":62,"paddingLeft":0,"paddingRight":0}} content={"<span style=\"color: var(--black);\">Letní koktejl párty!</span><br>"}>
              </Title>

              <Text className="text-box text-box--center fs--22 w--500" style={{"maxWidth":918,"paddingLeft":0,"marginBottom":50,"paddingRight":0,"paddingBottom":46}} content={"Léto ještě nekončí! Seberte své přátele a přijďte se bavit na koktejlovou párty, která startuje právě 23.8.2024 od 16 hodin.&nbsp;<br><span style=\"font-weight: bold;\"><br>16 - 17 hodina</span><br>&nbsp;<span style=\"font-weight: bold;\">DĚTSKÁ HAPPY HOUR</span><br>(dětské nápoje, fotokoutek, ...)<br><br><span style=\"font-weight: bold;\">17 - 18 hodina</span><br><span style=\"font-weight: bold;\">HAPPY HOUR</span><br><br><span style=\"font-weight: bold;\">18 - 21 hodina<br>DJ BAKER</span><br><br><span style=\"font-weight: bold;\">Na co se můžete těšit?</span><br>super muzika<br>míchané koktejly<br><span style=\"font-weight: bold;\">představení koktejlu MARGANETKA ;-)</span><br>fotokoutek pro dospělé<br>dobrá zábava<br><br>Nenechte si ujít letní zábavu a přijďte se bavit.<br>&nbsp;Těší se na Vás tým kavárny Manetka.<br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr4 btn-box--pbtn4 btn-box--shape5 btn-box--cColor2 btn-box--shadow5 fs--20 w--600" use={"page"} href={"/akce#rychla_rezervace"} content={"Rezervovat si stůl"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":950}}>
              
              <Image className="--shape4 --shadow4" alt={"Letní koktejl párty"} src={"https://cdn.swbpg.com/t/34102/50766c14483749d7846f039bb4e6fccd_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/34102/50766c14483749d7846f039bb4e6fccd_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/50766c14483749d7846f039bb4e6fccd_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/50766c14483749d7846f039bb4e6fccd_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/50766c14483749d7846f039bb4e6fccd_s=1400x_.jpg 1400w"} lightbox={true} description={"Hurááá prázdniny"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1orm3ur css-42e4bw --parallax pb--80 pt--80" name={"rychla_rezervace"} parallax={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/92095680b9ff4161a906a73f6221815a_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/92095680b9ff4161a906a73f6221815a_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/92095680b9ff4161a906a73f6221815a_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/92095680b9ff4161a906a73f6221815a_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/92095680b9ff4161a906a73f6221815a_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/92095680b9ff4161a906a73f6221815a_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/92095680b9ff4161a906a73f6221815a_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/92095680b9ff4161a906a73f6221815a_s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --shape4 el--1 pb--20 pt--20" style={{"backgroundColor":"rgba(0,0,0,0.6)"}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":900}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--white);\">Rychlá rezervace</span>"}>
              </Title>

              <Title className="title-box title-box--left fs--43 w--400 ls--02 lh--16 pb--12 pt--16" content={"<span style=\"color: white;\">kavárna a cukrárna MANETKA<br><a href=\"tel:+420773300833\">+420 773 300 833</a><br><a href=\"mailto:kavarna@manetka.cz?subject=Rezerva%C4%8Dn%C3%AD+formul%C3%A1%C5%99+z+webu\" target=\"_blank\">kavarna@manetka.cz</a><br></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"backgroundColor":"rgba(135,140,149,1)","paddingTop":83,"paddingBottom":80}} name={"galerieakci"}>
          
          <ColumnWrap className="column__flex --center el--1 mb--20 flex--top" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center ff--2 fs--86 w--400" content={"<span style=\"color: var(--white);\">Galerie akcí</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="mt--0 pt--0">
              
              <Text className="text-box text-box--center fs--26" style={{"maxWidth":664}} content={"<span style=\"color: var(--white);\">Prohlédněte si galerii proběhlých akcí. Tohle vše u nás můžete zažít! Stačí sledovat naše webové stránky či sociální sítě ;-)</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4 flex--top" style={{"maxWidth":"","paddingBottom":0,"marginTop":63,"paddingTop":0}} columns={"4"}>
            
            <ColumnWrapper className="--hvr5 --left">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/49261f0bc4024c26abc23bf1d22c97db_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/34102/49261f0bc4024c26abc23bf1d22c97db_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/49261f0bc4024c26abc23bf1d22c97db_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/49261f0bc4024c26abc23bf1d22c97db_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5 --left">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/a472862c918b44e6bc6ee0843a5c1fdf_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/34102/a472862c918b44e6bc6ee0843a5c1fdf_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/a472862c918b44e6bc6ee0843a5c1fdf_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/a472862c918b44e6bc6ee0843a5c1fdf_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/a472862c918b44e6bc6ee0843a5c1fdf_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/34102/a472862c918b44e6bc6ee0843a5c1fdf_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":306}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/f1c8d8ed373e427d829f3b7e99977a8d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/f1c8d8ed373e427d829f3b7e99977a8d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/f1c8d8ed373e427d829f3b7e99977a8d_s=860x_.jpg 860w"} alt={""} src={"https://cdn.swbpg.com/t/34102/f1c8d8ed373e427d829f3b7e99977a8d_s=660x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":306}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/f1c8d8ed373e427d829f3b7e99977a8d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/f1c8d8ed373e427d829f3b7e99977a8d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/f1c8d8ed373e427d829f3b7e99977a8d_s=860x_.jpg 860w"} alt={""} src={"https://cdn.swbpg.com/t/34102/f1c8d8ed373e427d829f3b7e99977a8d_s=660x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":360}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/a618fb6f598b4c31951b5329f38abe37_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/a618fb6f598b4c31951b5329f38abe37_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/a618fb6f598b4c31951b5329f38abe37_s=860x_.jpg 860w"} alt={""} src={"https://cdn.swbpg.com/t/34102/a618fb6f598b4c31951b5329f38abe37_s=660x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":360}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/7cb360b5e8c846c2a81ef4ecc647fd7e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/7cb360b5e8c846c2a81ef4ecc647fd7e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/7cb360b5e8c846c2a81ef4ecc647fd7e_s=860x_.jpg 860w"} alt={""} src={"https://cdn.swbpg.com/t/34102/7cb360b5e8c846c2a81ef4ecc647fd7e_s=660x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":360}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/72d8f33fd57d433c8338000ec445702f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/72d8f33fd57d433c8338000ec445702f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/72d8f33fd57d433c8338000ec445702f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/72d8f33fd57d433c8338000ec445702f_s=1400x_.jpg 1400w"} alt={""} src={"https://cdn.swbpg.com/t/34102/72d8f33fd57d433c8338000ec445702f_s=860x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":360}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/96702f4485a04efd95cb5cb6bced0baa_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/96702f4485a04efd95cb5cb6bced0baa_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/96702f4485a04efd95cb5cb6bced0baa_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/96702f4485a04efd95cb5cb6bced0baa_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/34102/96702f4485a04efd95cb5cb6bced0baa_s=2000x_.jpg 2000w"} alt={""} src={"https://cdn.swbpg.com/t/34102/96702f4485a04efd95cb5cb6bced0baa_s=860x_.jpg"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 mt--50 flex--top" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper className="mt--0 pt--0">
              
              <Button className="btn-box btn-box--hvr4 btn-box--shape5 btn-box--cColor1 btn-box--shadow5" style={{"backgroundColor":"var(--white)"}} content={"<span style=\"color: var(--color-custom-1--15);\">Celá galerie akcí</span>"} use={"page"} href={"/fotogalerie#foto_akce"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":0,"paddingTop":104,"paddingBottom":103}} name={"paticka"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" style={{"maxWidth":1406,"paddingLeft":0,"marginBottom":0}} columns={"3"} fullscreen={false}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--20 w--400 ls--002 lh--14" content={"<span style=\"font-weight: bold; color: var(--black);\">WEB</span>"}>
              </Title>

              <Text className="text-box fs--18 w--400 ls--002 lh--16" style={{"marginTop":14,"marginBottom":0,"paddingBottom":30}} content={"<span style=\"color: var(--black);\"><span style=\"text-decoration-line: underline;\"><a href=\"/#bcckfdbfejb\" style=\"\">O nás</a></span><a href=\"/menu#menu_uvod\"><br>Stálé menu<br></a><a href=\"/akce#j40igf0u8gp\">Akce</a><a href=\"/menu#menu_uvod\" style=\"\"><br></a><a href=\"/salonek#salonek_uvod\" style=\"\">Salónek</a><a href=\"/menu#menu_uvod\"><br></a><a href=\"/kontakt#kontakty\" style=\"\">Kontakt</a></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--20 w--400 ls--002 lh--14" content={"<span style=\"font-weight: bold; color: var(--black);\">KAVÁRNA A CUKRÁRNA MANETKA</span>"}>
              </Title>

              <Text className="text-box fs--18 w--400 ls--002 lh--16" style={{"maxWidth":396,"marginTop":13,"paddingRight":0,"paddingBottom":0}} content={"Manětínská 30, Plzeň<br>tel: 773 300 833, email: kavarna@manetka.cz<br><span style=\"text-decoration-line: underline; font-weight: bold; color: var(--black);\"><a href=\"https://www.google.com/maps/place/Manetka+cafe/@49.7800782,13.3664327,3a,90y,117.55h,89.06t/data=!3m7!1e1!3m5!1sAF1QipPbAylCirgJw9XZmzsz7O3ipPU8vZNmJViK7vnr!2e10!3e12!7i11000!8i5500!4m9!3m8!1s0x470af33bb34100a5:0x17c49104135d9431!8m2!3d49.7798029!4d13.3665938!10e5!14m1!1BCgIgARICCAI!16s%2Fg%2F11l2m45152?entry=ttu\" target=\"_blank\" style=\"\">VIRTUÁLNÍ PROHLÍDKA KAVÁRNY</a></span><br><br><span style=\"font-weight: bold;\">OTEVÍRACÍ DOBA KAVÁRNY</span><br>Po - Ne 10:00 - 21:00<br>"}>
              </Text>

              <Image style={{"maxWidth":124,"marginTop":33}} alt={""} src={"https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=860x_.png"} use={"page"} href={"/#bcckfdbfejb"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=350x_.png 350w, https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=660x_.png 660w, https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=860x_.png 860w, https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=1400x_.png 1400w, https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=2000x_.png 2000w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--20 w--400 ls--002 lh--14" content={"<span style=\"font-weight: bold; color: var(--black);\">SLEDUJ NÁS</span>"}>
              </Title>

              <Image style={{"maxWidth":64,"marginTop":31}} alt={"Facebook"} src={"https://cdn.swbpg.com/t/34102/556bd886db6346cc9a4b6ae94cde69b1_s=350x_.png"} url={"https://www.facebook.com/Manetkacafe"} use={"url"} href={"https://www.facebook.com/Manetkacafe"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/34102/556bd886db6346cc9a4b6ae94cde69b1_s=350x_.png 350w"} lightbox={false}>
              </Image>

              <Image style={{"maxWidth":64,"marginTop":35}} alt={"Instagram"} src={"https://cdn.swbpg.com/t/34102/9fa843421abe4a449de7c1ad3493add4_s=350x_.png"} url={"https://www.instagram.com/manetka_cafe/"} use={"url"} href={"https://www.instagram.com/manetka_cafe/"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/34102/9fa843421abe4a449de7c1ad3493add4_s=350x_.png 350w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}